/**
 * Our global data/state-store
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 *
 * https://medium.com/@samuelresua/global-state-with-react-context-cfa99946900d
 */
import React, {cloneElement} from 'react';
import {Cart} from './Providers/Cart';
import Globals from './Providers/Globals';
import {MiniCart} from './Providers/MiniCart';
import {Checkout} from './Providers/Checkout';
import {User} from './Providers/User';
import {ActivePopup} from './Providers/ActivePopup';
import {ShopMapFilter} from './Providers/ShopMapFilter';

// Collecting the providers
const providers = [
    <Cart.Provider key={0}/>,
    <MiniCart.Provider key={1}/>,
    <Checkout.Provider key={2}/>,
    <User.Provider key={3}/>,
    <ActivePopup.Provider key={4}/>,
    <ShopMapFilter.Provider key={5}/>,
];

// Store-Wrapper
const Store = ({children: initial}) => providers.reduce((children, parent, key) => cloneElement(
    parent,
    {children, key},
), initial);

// Export everything
export {
    Store,
    Cart,
    Globals,
    MiniCart,
    Checkout,
    ActivePopup,
    User
};
