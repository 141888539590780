/**
 * Wrapping the pageElement
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import loadable from '@loadable/component';

const Globals = {
    de: loadable(() => import('.components/Store/Globals__de')),
    en: loadable(() => import('.components/Store/Globals__en')),
    fr: loadable(() => import('.components/Store/Globals__fr')),
};

export const wrapPageElement = ({element, props}) => props?.pageContext?.page?.language?.slug
    ? React.createElement(
        Globals[props.pageContext.page.language.slug],
        {},
        element,
    )
    : element;
