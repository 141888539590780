import {gql} from '@apollo/client';
import cartData from '../data/cart.data';

const GET_CART = gql`
    query GET_CART {
      ${cartData({recalculateTotals: true})}
    }
`;

export default GET_CART;
