/**
 * Wrapping the root element in our providers
 */
// NPM
import React from 'react';
import {ApolloProvider} from '@apollo/client';

// Peritus
import {Store} from './Store';
import {client} from './Apollo';

export const wrapRootElement = ({element}) => (<ApolloProvider client={client}>
    <Store>{element}</Store>
</ApolloProvider>);
