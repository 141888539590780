/**
 * Our checkout-state
 */
import React, {useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';

// Context
const State = React.createContext();
const Dispatch = React.createContext();

// Reducer
const reducer = (state, action) => {
    switch (action.type) {
        case 'update': {
            const newState = {...state, ...action.checkout};
            localStorage.setItem('checkout', JSON.stringify(newState));
            return newState;
        }
        case 'nextStep': {
            window.checkoutNavigation.scrollIntoView();
            return {
                ...state,
                currentStep: state.currentStep + 1,
            };
        }
        case 'previousStep': {
            window.checkoutNavigation.scrollIntoView();
            return {
                ...state,
                currentStep: state.currentStep - 1,
            };
        }
        case 'setStep': {
            window.checkoutNavigation.scrollIntoView();
            return {
                ...state,
                currentStep: action.step,
            };
        }
        case 'clear': {
            localStorage.setItem('checkout', null);
            return null;
        }
        default: {
            return state;
        }
    }
};

const Provider = ({children}) => {
    const [state, dispatch] = useReducer(
        reducer,
        {currentStep: 0}
    );

    useEffect(() => {
        const checkout = localStorage.getItem('checkout');

        if (checkout) {
            dispatch({type: 'update', checkout: JSON.parse(checkout)});
        }
    }, []);

    return (<State.Provider value={state}>
        <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>);
};

Provider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
};

// Export
export const Checkout = {
    State,
    Dispatch,
    Provider,
};
