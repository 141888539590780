/**
 * Our CLEAR_CART mutation
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import {gql} from '@apollo/client';

const CLEAR_CART = gql`
  mutation {
    emptyCart(input: {clearPersistentCart: true}) {
      clientMutationId
    }
  }
`;

export default CLEAR_CART;
