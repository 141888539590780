/**
 * Our mini-cart-state
 */
import React from 'react';
import PropTypes from 'prop-types';

// Context
const State = React.createContext();
const Dispatch = React.createContext();

// Reducer
const reducer = (state, action) => {
    switch (action) {
        case 'show':
            return {
                ...state,
                show: true,
            };
        case 'hide':
            return {
                ...state,
                show: false,
            };
        case 'toggle':
            return {
                ...state,
                show: !state.show,
            };
        default:
            return state;
    }
};


const Provider = ({children}) => {
    const [state, dispatch] = React.useReducer(reducer, {show: false});

    return (<State.Provider value={state}>
        <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>);
};

Provider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

// Export
export const MiniCart = {
    State,
    Dispatch,
    Provider,
};
