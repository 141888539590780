/**
 * Internal dependencies.
 */
import fetch from 'isomorphic-fetch';
import {ApolloClient, createHttpLink, InMemoryCache, ApolloLink} from '@apollo/client';
import {isEmpty} from 'lodash';

/**
 * Middleware operation
 *
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 * If we have a auth token in localStorage, add it to the GraphQL request as a authorization header.
 */
export const middleware = new ApolloLink((operation, forward) => {

    let headersData = null;

    if (typeof window !== 'undefined') {
        let auth;

        try {
            auth = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('auth')) : null;
        } catch (e) {
            console.error('Could not parse auth');
            localStorage.removeItem('auth');
        }

        /**
         * If session data exist in local storage, set value as session header.
         */
        const session = localStorage.getItem('woo-session');
        if (!isEmpty(session)) {
            headersData = {
                'woocommerce-session': `Session ${session}`,
            };
        }

        /**
         * If auth token exist in local storage, set value as authorization header.
         */
        if (!isEmpty(auth?.authToken) && operation.getContext().withAuth) {
            headersData = {
                ...headersData,
                'authorization': `Bearer ${auth.authToken}`,
            };
        }
    }

    if (!isEmpty(headersData)) {
        operation.setContext(() => ({
            headers: headersData,
        }));
    }

    return forward(operation);
});

/**
 * Afterware operation.
 *
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) => forward(operation).map(response => {
    const context = operation.getContext();
    const {response: {headers}} = context;

    // Refresh woo-session
    const session = headers.get('woocommerce-session');
    if (session && localStorage.getItem('woo-session') !== session) {
        localStorage.setItem('woo-session', session);
    }

    // Refresh the auth-token
    const token = headers.get('x-jwt-refresh');
    if (!isEmpty(token)) {
        let auth = JSON.parse(localStorage.getItem('auth'));
        auth.authToken = token;
        auth.user.sessionToken = !isEmpty(session) ? session : auth.user.sessionToken;

        localStorage.setItem('auth', JSON.stringify(auth));
    }

    // Redirect to login
    if (response.errors && response.errors[0]?.debugMessage?.startsWith('invalid-jwt')) {
        localStorage.removeItem('auth');
        window.location.reload();
    }

    return response;
}));

/**
 * Apollo GraphQL client.
 */
export const client = new ApolloClient({
    link: middleware.concat(afterware.concat(createHttpLink({
        uri: `${process.env.GATSBY_WORDPRESS_ADMIN}/wp/graphql`,
        fetch: fetch,
    }))),
    cache: new InMemoryCache(),
});
