/**
 * Our cart-state
 */
import React, {useEffect, useReducer} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import GET_CART from 'queries/queries/get-cart';
import PropTypes from 'prop-types';
import CLEAR_CART from 'queries/mutations/clear-cart.graphql';
// for some reason importing from 'utilities' breaks build
import {PRODUCT_CART_PENDING} from '../../Helper/Cart';

// Context
const State = React.createContext();
const Dispatch = React.createContext();

// Reducer
const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            localStorage.setItem('cart', JSON.stringify(action.cart));
            return action.cart;
        case 'clear':
            localStorage.setItem('cart', null);

            return {status: 'clearing'};
        case 'cleared':
            return null;
        case 'clearUnconfirmed':
            if (!action?.cart?.contents?.nodes?.length) {
                // cart is empty, and this should never happen in clearUnconfirmed
                return null;
            }

            action.cart.contents.nodes = action.cart.contents.nodes.filter(product => !product.extraData?.find(item => item.key === PRODUCT_CART_PENDING)?.value);
            localStorage.setItem('cart', JSON.stringify(action.cart));
            return action.cart;
        case 'insert':
            if (!action.product) {
                return action.cart;
            }

            action.cart.contents.nodes.push(action.product);
            localStorage.setItem('cart', JSON.stringify(action.cart));

            return action.cart;
        default:
            return state;
    }
};

const Provider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, {status: 'loading'});

    useQuery(GET_CART, {
        context: {withAuth: true},
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data?.cart && !data?.cart?.isEmpty) {
                dispatch({type: 'update', cart: data.cart});
                return;
            }

            dispatch({type: 'clear'});
        },
        onError: () => {
            dispatch({type: 'clear'});
        },
    });

    const [clearCart] = useMutation(CLEAR_CART, {
        context: {withAuth: true},
        fetchPolicy: 'no-cache',
        onCompleted: () => dispatch({type: 'cleared'}),
        onError: () => {
            // Do nothing
        },
    });

    useEffect(() => {
        if ('clearing' === state?.status) {
            clearCart();
        }
    }, [state]);


    return (<State.Provider value={state}>
        <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>);
};

Provider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

// Export
export const Cart = {
    State,
    Dispatch,
    Provider,
};
