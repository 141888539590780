export const PRODUCT_CART_PENDING = 'pending';

export const productPending = ({title, image, color, size, quantity}) => {
    return {
        'key': 'tmp-product',
        'product': {
            'node': {
                'name': title,
                'type': 'VARIABLE',
            },
        },
        'variation': {
            'node': {
                'name': title,
                'type': 'VARIATION',
                'price': '0,00€',
                'regularPrice': '0,00',
            },
        },
        'extraData': [
            {
                'key': 'cart_image',
                'value': image?.url,
            },
            {
                'key': 'pa_color',
                'value': JSON.stringify(color),
            },
            {
                'key': 'pa_size',
                'value': JSON.stringify(size),
            },
            {
                'key': PRODUCT_CART_PENDING,
                'value': true,
            }
        ],
        'quantity': quantity,
        'total': '0,00€',
        'subtotal': '0,00€',
        'subtotalTax': '0,00€',
    };
};

export const emptyCartWithProductPending = ({title, image, color, size, quantity}) => {
    return {
        'isEmpty': false,
        'contents': {
            'nodes': [
                productPending({title, image, color, size, quantity}),
            ],
        },
        'appliedCoupons': null,
        'subtotal': '0,00€',
        'subtotalTax': '0,00€',
        'shippingTax': '0,00€',
        'shippingTotal': '0,00€',
        'total': '0,00€',
        'totalTax': '0,00€',
        'feeTax': '0,00€',
        'feeTotal': '0,00€',
        'discountTax': '0,00€',
        'discountTotal': '0,00€',
    };
};
