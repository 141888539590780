/**
 * Our user-state
 */
import React, {useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';

// Context
const State = React.createContext();
const Dispatch = React.createContext();

// Reducer
const reducer = (state, action) => {
    let data;

    switch (action.type) {
        case 'login':
            data = JSON.stringify(action.authData);

            localStorage.setItem('auth', data);
            localStorage.setItem('woo-session', action.authData.user?.sessionToken);

            return {
                isLoggedIn: true,
                authData: action.authData,
            };
        case 'logout':
            localStorage.removeItem('auth');
            localStorage.removeItem('woo-session');

            return {
                isLoggedIn: false,
                authData: null,
            };
        default:
            return state;
    }
};

const Provider = ({children}) => {
    const [state, dispatch] = useReducer(
        reducer,
        {
            isLoggedIn: false,
            authData: null,
        },
    );

    useEffect(() => {
        const authData = localStorage.getItem('auth');

        if (authData) {
            dispatch({type: 'login', authData: JSON.parse(authData)});
        }
    }, []);

    return (<State.Provider value={state}>
        <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>);
};

Provider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.elementType,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.arrayOf(PropTypes.elementType),
    ]),
};

// Export
export const User = {
    State,
    Dispatch,
    Provider,
};
